@media (max-width: 1600px) {
}

@media (max-width: 1500px) {
  .screen-sec-box h2 {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: absolute;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    left: 10%;
    top: -80px;
  }
  .banner-img-side img {
    width: 500px;
  }

  .banner-img-pis img {
    width: 240px;
  }
  .banner-img-pis {
    right: 0px;
  }
}

@media (max-width: 1400px) {
  .common-page-left-part {
    width: 38%;
  }

  .common-page-right-part {
    width: 60%;
  }
  .right-side {
    margin-left: auto;
    /* width: calc(100% - 310px); */
    width: 77%;
  }
  .banner-img-side img {
    width: 480px;
  }

  .banner-img-pis img {
    width: 240px !important;
  }
  .banner-img-pis {
    right: -50px;
  }
}

@media (max-width: 1300px) {
  .banner-img-side img {
    width: 450px;
  }

  .banner-img-pis img {
    width: 200px !important;
  }
  .banner-img-pis {
    right: -10px;
  }
}

@media (max-width: 1200px) {
  .banner-img-side img {
    width: 500px;
  }

  .banner-img-pis img {
    width: 250px;
  }
  .banner-img-pis {
    right: -50px;
  }
}
@media (max-width: 991px) {
}
@media (max-width: 768px) {
  .slider-nav.testimonials-small-img {
    display: flex !important;
    justify-content: center;
  }
  .connect-forever-sec {
    padding-bottom: 40px !important;
  }

  .get-the-app-sec {
    padding: 10px 0;
  }
}

@media (max-width: 576px) {
  .get-the-app-sec {
    padding: 150px 0;
  }
  .footer-sec {
    padding: 20px 0px;
  }
  .contact-us-card {
    padding: 30px 20px;
  }
  .testimonials-card img {
    width: 160px;
  }
  .testimonials-card {
    gap: 20px;
  }
  .testimonials-small-img img {
    width: 80px;
    height: 80px;
  }
  .testimonials-sec .slick-arrow.slick-prev {
    display: none !important;
  }
  .testimonials-sec .slick-arrow.slick-next {
    display: none !important;
  }
  .main-navbar {
    border-radius: 10px;
  }

  .banner-sec {
    padding-top: 180px;
  }
  .banner-img-side img {
    width: 290px;
  }
  .banner-img-pis {
    right: 0px;
    top: 0;
  }
  .banner-img-side {
    margin-top: 40px;
  }

  .connect-forever-img img {
    width: 250px;
  }

  .connect-forever-part img {
    width: 90px;
  }
  .connect-forever-part {
    position: absolute;
    top: 0;
    right: 10px;
  }

  .testimonials-card {
    flex-direction: column;
  }
  .testimonials-card-text {
    width: 100%;
    text-align: center;
  }

  .footer-img-side img {
    display: none;
  }
  .get-the-app-img {
    margin-top: 50px;
  }
  .testimonials-sec {
    padding: 20px 0;
    padding-top: 120px;
  }
  .testimonials-card img {
    width: 220px;
  }

  .get-the-app-text h2 {
    font-size: 30px;
  }

  .get-the-app-text p {
    font-size: 15px;
  }
  .testimonials-card-text p {
    font-size: 15px;
  }
  .testimonials-card-text h2 {
    font-size: 35px;
  }
  .testimonials-head h2 {
    font-size: 30px;
  }
  .screen-sec-box h2 {
    font-size: 30px;
    left: 3%;
    top: -30px;
  }
  .connect-forever-text h2 {
    font-size: 30px;
  }
  .connect-forever-text p {
    font-size: 15px;
  }
  .connect-forever-sec {
    padding: 140px 0px;
  }
  .banner-text-side h1 {
    font-size: 30px;
  }
  .banner-text-side p {
    font-size: 15px;
  }
}
@media (max-width: 480px) {
}
