input:focus-visible {
  border: 0 !important;
  outline: none !important;
}

/* :focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0;
  } */

body {
  background-color: #f1e6ec;
}

.main-navbar a img {
  width: 120px;
  height: 100%;
}

.footer-logo img {
  width: 120px;
}

.banner-sec {
  /* background-image: url("../images//main-banner.png"); */
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-top: 200px;
  display: flex;
  align-items: center;
}

.main-navbar {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.1);
  padding: 0px 20px;
  position: relative;
  z-index: 999;
  top: 10px;
}

section.header-sec {
  left: 0;
  position: fixed;
  right: 0;
  transition: 0.5s ease;
  z-index: 1111;
}

.main-navbar a.nav-link {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin: 0px 10px;
}

.theme-btn {
  border-radius: 10px;
  border: 1px solid #ff891d;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.08);
  background: #ec456a;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 18px;
  text-decoration: none;
}

.banner-img-side img {
  width: 550px;
}

.banner-img-pis img {
  width: 300px;
}

.banner-img-side {
  position: relative;
}

.banner-img-pis {
  position: absolute;
  right: -90px;
  top: 50px;
}

.banner-text-side h1 {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  font-weight: 700;
}

.banner-text-side h1 span {
  background: #ec456a;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.banner-text-side p {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  /* line-height: normal; */
  margin: 20px 0px;
}

.banner-text-side a {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  background: #ff891d;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border: 1px solid #ff891d;
  padding: 8px 0px 10px 18px;
  border-radius: 10px;
}
.connect-forever-sec {
  /* background-image: url("../images/second-banner.png"); */
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;

  padding-top: 215px;
}
.screen-sec {
  padding-top: 140px;
}
.connect-forever-img img {
  width: 100%;
}

.connect-forever-img {
  position: relative;
}

.connect-forever-part {
  position: absolute;
  top: 0;
  right: -10px;
}

.connect-forever-part img {
  width: 100px;
}

.connect-forever-text h2 {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  font-weight: 700;
}

.connect-forever-text p {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 500;
}

.get-the-app-sec {
  /* background-image: url("../images//get-app-banner.png"); */
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 0;
  padding-bottom: 0px;
}

.get-the-app-img img {
  width: 100%;
}

.get-the-app-text h2 {
  background: #ec456a;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Poppins", sans-serif;
  font-size: 60px;
  font-weight: 700;
}

.get-the-app-text p {
  color: #474747;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 500;
}

.play-store-links {
  display: flex;
  gap: 10px;
}

.play-store-links img {
  width: 160px;
}

.footer-logo {
  margin-bottom: 20px;
}

.footer-list h3 {
  color: #180f2a;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 500;
}

.footer-list ul {
  list-style: none;
  padding: 0;
}

.footer-list ul li {
  color: #180f2a;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  margin-bottom: 5px;
  text-decoration: none;
}

.footer-list ul li a {
  color: #180f2a;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  margin-bottom: 5px;
  text-decoration: none;
}

.footer-sec {
  border-radius: 20px 20px 0px 0px;
  background: #fff;
  box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.1);
  padding: 90px 0px;
}

.footer-social-icons ul {
  padding: 0;
  list-style: none;
}

.footer-social-icons ul li img {
  width: 50px;
}

.copy-right-line p {
  color: rgba(24, 15, 42, 0.3);
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 110%;
  margin: 0;
}

.footer-img-side img {
  width: 290px;
  position: absolute;
  right: 0px;
  bottom: 0;
  display: inline;
}

.screen-sec-box img {
  width: 100%;
}

.screen-sec-box h2 {
  background: #ec456a;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: absolute;
  font-family: "Poppins", sans-serif;
  font-size: 60px;
  font-weight: 700;
  left: 18%;
  top: -50px;
}

.testimonials-sec {
  /* background-image: url("../images/testimonials-banner.png"); */
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 0;
  padding-top: 80px;
}

.testimonials-card {
  display: flex;
  align-items: center;
  gap: 60px;
}

.testimonials-card-text h2 {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 45px;
  font-weight: 700;
}

.testimonials-card-text p {
  color: #474747;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
}

.testimonials-card-text {
  width: 50%;
}

.testimonials-card img {
  width: 340px;
}

/* 
  .slider-container {
      display: flex;
  } */

.testimonials-small-img img {
  width: 50px;
  height: 50px;
  margin: 6px 0;
}

.testimonials-small-img-list {
  display: flex;
  flex-direction: column;
}

.testimonials-small-img-list .slick-track {
}

.testimonials-head h2 {
  background: #ec456a;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Poppins", sans-serif;
  font-size: 60px;
  font-weight: 700;
  margin-bottom: 40px;
}

button.slick-arrow.slick-next {
  display: none !important;
}

button.slick-arrow.slick-prev {
  display: none !important;
}

.social-media-icons ul {
  display: flex;
  gap: 30px;
  list-style: none;
  padding: 0;
}

.social-media-icons ul li svg path {
  fill: #000;
}

.contact-us-sec {
  padding: 100px 0px;
  padding-top: 110px;
}

.contact-us-card {
  background-color: #fff;
  border-radius: 22px;
  box-shadow: 0 4px 30px #ec456a17;
  padding: 50px 60px;
  width: 100%;
  text-align: center;
}

.contact-us-card h3 {
  background: #ec456a;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 20px;
}

.contact-us-card textarea {
  resize: none;
  border: 1px solid #ff891d;
}

.contact-us-card input {
  border: 1px solid #ff891d;
  height: 50px;
}

.main-navbar .navbar-nav a {
  color: #000 !important;
  font-family: "Poppins", sans-serif;
  font-size: 16px !important;
  font-weight: 500 !important;
  margin: 0px 10px;
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  background: 0 0;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
